import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultPhoneInput.scss'

// phone code config
export const ACCEPT_CODE_COUNTRIES = ['us', 'ca', 'au']
const MyDefaultPhoneInput = ({
  disabled,
  onEnterPress,
  hasFeedback = false,
  name,
  label,
  placeholder,
  disableDropdown = true,
  initialCountry,
  value,
  onChange,
  autoCompleteCountryCode,
  formClassName,
  formStyle,
  isForm,
  required = false,
  errorMessage,
  onBlur,
  color = 'grey',
  isFirst,
  isLast,
}) => {
  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onEnterPress && onEnterPress()
    }
  }

  const [phoneNumber, setPhoneNumber] = useState('')
  const [correctPhoneNumber, setCorrectPhoneNumber] = useState('')

  useEffect(() => {
    document.querySelectorAll('.my_default_phone_input ')[0] &&
      document
        .querySelectorAll('.my_default_phone_input ')[0]
        .addEventListener('paste', correctPhoneNumberFormat)
  }, [document.querySelectorAll('.my_default_phone_input')])

  const correctPhoneNumberFormat = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData('text')
    pasteValue = pasteValue.replace(/-|\s/g, '')
    pasteValue = pasteValue.replace('(', '')
    pasteValue = pasteValue.replace(')', '')
    pasteValue = pasteValue.replace('tel:', '')
    pasteValue = pasteValue.replace('+', '')
    if (initialCountry === 'au') {
      if (pasteValue.length <= 11) {
        if (pasteValue.startsWith('04')) {
          pasteValue = `04${pasteValue && pasteValue.slice(2)}`
        } else {
          pasteValue = `04${pasteValue && pasteValue}`
        }
      }
    } else {
      if (pasteValue.length <= 10) {
        pasteValue = `1${pasteValue}`
      }
    }
    setCorrectPhoneNumber(pasteValue)
    setPhoneNumber(pasteValue)
  }

  const handleKeyDown = (event) => {
    if (Boolean(correctPhoneNumber)) {
      return
    }
    if (event.key === 'Enter') {
      event.preventDefault()
      onEnterPress && onEnterPress()
      return
    }
    if (event.key.match(/^\d+$/)) {
      if (!phoneNumber) {
        event.preventDefault()
        if (initialCountry === 'au') {
          setPhoneNumber('04' + event.key)
          onChange('04' + event.key)
        } else {
          setPhoneNumber('+1' + event.key)
          onChange('+1' + event.key)
        }
        const input = event.target
        if (initialCountry === 'au') {
          setTimeout(() => input.setSelectionRange(7, 7), 0)
        } else {
          setTimeout(() => input.setSelectionRange(5, 5), 0)
        }
      }
    }
  }

  const handleChange = (value) => {
    if (correctPhoneNumber) {
      onChange(correctPhoneNumber)
    } else {
      setPhoneNumber(value)
    }
    Boolean(correctPhoneNumber) && setCorrectPhoneNumber('')
  }

  return isForm ? (
    <MyDefaultFormItem
      formClassName={formClassName}
      label={label}
      name={name}
      formStyle={formStyle}
      hasFeedback={hasFeedback}
      isFirst={isFirst}
      isLast={isLast}
      rules={[
        {
          required: required,
          message: errorMessage || `Please input ${placeholder || label}!`,
          validator: async (_, value) => {
            if (value === '04') {
              return Promise.reject(new Error(errorMessage))
            }
          },
        },
      ]}
    >
      <PhoneInput
        country={initialCountry}
        inputClass={`my_default_phone_input ${color}`}
        onlyCountries={ACCEPT_CODE_COUNTRIES}
        areaCodes={{ au: ['+04'] }}
        disabled={disabled}
        disableDropdown={disableDropdown}
        value={autoCompleteCountryCode ? phoneNumber : value}
        placeholder={initialCountry === 'au' ? '(04) 1234 5678' : '1 (702) 123-4567'}
        specialLabel={null}
        onChange={
          autoCompleteCountryCode
            ? handleChange
            : (value) => {
                let finalValue
                if (correctPhoneNumber) {
                  finalValue = correctPhoneNumber
                } else {
                  finalValue = value
                }
                onChange(finalValue)
                Boolean(correctPhoneNumber) && setCorrectPhoneNumber('')
              }
        }
        disableCountryCode={initialCountry === 'au'}
        masks={{ au: '(..) .... ....' }}
        dropdownStyle={{ bottom: 35, borderRadius: 4 }}
        inputStyle={{ width: '100%', height: 48 }}
        onKeyDown={autoCompleteCountryCode ? handleKeyDown : keyDownHandler}
        onBlur={onBlur}
      />
    </MyDefaultFormItem>
  ) : (
    <PhoneInput
      country={initialCountry}
      inputClass={`my_default_phone_input ${color}`}
      onlyCountries={ACCEPT_CODE_COUNTRIES}
      areaCodes={{ au: ['+04'] }}
      disabled={disabled}
      specialLabel={null}
      disableDropdown={disableDropdown}
      value={autoCompleteCountryCode ? phoneNumber : value}
      placeholder={initialCountry === 'au' ? '(04) 1234 5678' : '1 (702) 123-4567'}
      onChange={
        autoCompleteCountryCode
          ? handleChange
          : (value) => {
              let finalValue
              if (correctPhoneNumber) {
                finalValue = correctPhoneNumber
              } else {
                finalValue = value
              }
              onChange(finalValue)
              Boolean(correctPhoneNumber) && setCorrectPhoneNumber('')
            }
      }
      disableCountryCode={initialCountry === 'au'}
      masks={{ au: '(..) .... ....' }}
      dropdownStyle={{ bottom: 35, borderRadius: 4 }}
      inputStyle={{ width: '100%', height: 48 }}
      onKeyDown={autoCompleteCountryCode ? handleKeyDown : keyDownHandler}
      onBlur={onBlur}
    />
  )
}

export default MyDefaultPhoneInput
