import * as React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='60'
    height='56.992'
    viewBox='0 0 60 56.992'
  >
    <g transform='translate(-461 -300.504)'>
      <path
        d='M566.009,405.849H537.854a3.4,3.4,0,0,1-2.476-1.072l-3.628-3.869a3.4,3.4,0,0,0-2.48-1.072H517.45a3.4,3.4,0,0,0-3.4,3.395V441.1a3.4,3.4,0,0,0,3.4,3.4H566a3.4,3.4,0,0,0,3.4-3.4V409.245A3.4,3.4,0,0,0,566.009,405.849Z'
        transform='translate(-51 -87)'
        fill='#14336a'
      />
      <path
        d='M522.18,387.5h41.262v36.945H522.18Z'
        transform='translate(-51 -87)'
        fill={props.listcolor || '#cee2ff'}
      />
      <path
        d='M520.016,390.934h40.006v35.689H520.016Z'
        transform='translate(-51 -87)'
        fill='#e8effb'
      />
      <path
        d='M524.539,395.473H555.5V397.1h-30.96Z'
        transform='translate(-51 -87)'
        fill={props.listcolor || '#cee2ff'}
      />
      <path
        d='M524.539,400.633H555.5v1.624h-30.96Z'
        transform='translate(-51 -87)'
        fill={props.listcolor || '#cee2ff'}
      />
      <path
        d='M524.539,405.793H555.5v1.624h-30.96Z'
        transform='translate(-51 -87)'
        fill={props.listcolor || '#cee2ff'}
      />
      <path
        d='M568.606,411.3H537.85a3.4,3.4,0,0,0-2.472,1.067l-3.63,3.854a3.392,3.392,0,0,1-2.472,1.068H515.4a3.4,3.4,0,0,0-3.4,3.395c0,.1.005.206.014.309l1.756,20.416a3.4,3.4,0,0,0,3.385,3.09H566.3a3.4,3.4,0,0,0,3.385-3.091l2.3-26.4a3.4,3.4,0,0,0-3.072-3.691C568.812,411.3,568.709,411.3,568.606,411.3Z'
        transform='translate(-51 -87)'
        fill={props.color || '#93bdff'}
      />
    </g>
  </svg>
)

const SVGUploadExcel = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGUploadExcel
