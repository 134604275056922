import React from 'react'
import { getText } from '../../lang'
import SVGSearch from '../../icons/SVG/SVGSearch'
import Div from '../Div/Div'
import InputText from '../InputText'
import './MyDefaultSearchInput.scss'

const MyDefaultSearchInput = ({ placeholder, value, onChange, className }) => {
  return (
    <Div className={`my_default_input_search ${className || ''}`}>
      <SVGSearch />
      <InputText
        className='my_default_input_search--input'
        placeholder={placeholder || getText('WORD_SEARCH')}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Div>
  )
}

export default MyDefaultSearchInput
