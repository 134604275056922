import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Iconly/Light/Chat'>
        <g id='vuesax/linear/voice-cricle'>
          <g id='voice-cricle'>
            <g id='Group'>
              <path
                id='Vector'
                d='M5.5 9.03833V12.9709'
                stroke='#0E0E0E'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                id='Vector_2'
                d='M8.25 7.72754V14.2726'
                stroke='#0E0E0E'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                id='Vector_3'
                d='M11 6.41669V15.5834'
                stroke='#0E0E0E'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                id='Vector_4'
                d='M13.75 7.72754V14.2726'
                stroke='#0E0E0E'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                id='Vector_5'
                d='M16.5 9.03833V12.9709'
                stroke='#0E0E0E'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </g>
            <path
              id='Vector_6'
              d='M11.0002 20.1666C16.0628 20.1666 20.1668 16.0626 20.1668 11C20.1668 5.93737 16.0628 1.83331 11.0002 1.83331C5.93755 1.83331 1.8335 5.93737 1.8335 11C1.8335 16.0626 5.93755 20.1666 11.0002 20.1666Z'
              stroke='#0E0E0E'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const SVGVoiceAiIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGVoiceAiIcon
