import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getText } from '../../../../../lang'
import locationActions from '../../../../../store/modules/locationActions'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { myWebViewPost } from '../../../../../utils'
import {
  DEALER_SOCKET,
  ELEADS,
  VIN_SOLUTION,
  ACTIVIX,
} from '../../../../../config/constants'
import MatButton from '../../../../../components/MatButton'
import './MessageCRMButton.scss'
import userActions from '../../../../../store/modules/userActions'

const MessageCenterHeaderCRMs = ({
  conversation,
  addToList,
  refreshConversation,
}) => {
  const [selectedLocation, setSelectedLocation] = useState()
  const [dropOptions, setDropOptions] = useState([])
  const [loading, setLoading] = useState(false)

  let organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  let user = useSelector((state) => {
    return state.authReducer.user
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const getCRMItem = (location) => {
    if (!location) {
      return false
    }

    const crmInfo = conversation?.crmInfo

    if (location.crmType === DEALER_SOCKET) {
      const entityId = crmInfo?.dealerSocket?.entityId || crmInfo?.crmCustomerId
      const eventId = crmInfo?.dealerSocket?.eventId || crmInfo?.crmLeadId

      // // V1
      // if (entityId) {
      //   return `https://bb.dealersocket.com/#/crm/view/contact/${entityId}`
      // }

      // v2
      if (entityId && eventId) {
        const dealerNumber = location.crmConfigs.dealerSocket.dealerNumber || ''
        const [siteId] = dealerNumber.split('_') // 7260_12 => 7260

        // https://inoxoft.atlassian.net/browse/MAT-3212
        return `https://bb.dealersocket.com/#/crm/sales/view/${entityId}/${eventId}?siteId=${siteId}&fullscreen`
      }

      // // V3
      // if (entityId) {
      //   return `https://bb.dealersocket.com/#/crm/customers/${entityId}`
      // }
    }

    if (location.crmType === ELEADS) {
      const customerId = crmInfo?.eLeads?.customer_id || crmInfo?.crmCustomerId
      if (customerId) {
        return `https://www.eleadcrm.com/evo2/fresh/eLead-V45/elead_track/newprospects/OpptyDetails.aspx?lPID=${customerId}`
      }
    }

    if (location.crmType === ACTIVIX) {
      const leadId = crmInfo?.activix?.lead_id || crmInfo?.crmLeadId
      if (leadId) {
        return `https://crm.activix.ca/leads/${leadId}`
      }
    }

    if (location.crmType === VIN_SOLUTION) {
      const customerId =
        crmInfo?.vinSolution?.contact?.ContactId || crmInfo?.crmCustomerId
      if (customerId) {
        return `https://apps.vinmanager.com/vinconnect/#/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=HIDE&rightpaneframe=/CarDashboard/Pages/CRM/CustomerDashboard.aspx?GlobalCustomerID=${customerId}`
      }
    }
  }

  const setCRMDropDownItems = (location = {}) => {
    const dropOptions = []
    setDropOptions(dropOptions)

    const canOpenInCRM = [
      DEALER_SOCKET,
      // ELEADS,
      VIN_SOLUTION,
      ACTIVIX,
    ].includes(location.crmType)

    const pushToCRM = {
      name: getText('WORD_PUSH'),
      value: 'push_to_crm',
    }

    const openInCRM = {
      name: getText('WORD_OPEN'),
      value: 'open_in_crm',
    }

    if (
      location.email &&
      conversation.kind !== 'gbm' &&
      userActions.getUserHasPermission(
        user,
        organization?.restrictions?.allowDisplayingPushToCRMButtonToSupportAgents,
        true
      )
    ) {
      dropOptions.push(pushToCRM)
    }

    if (canOpenInCRM) {
      if (getCRMItem(location)) {
        dropOptions.push(openInCRM)
      }
    }

    setDropOptions(dropOptions)
  }

  const sendCRM = async () => {
    if (conversation._id && selectedLocation._id) {
      setLoading(true)
      let result = await messageActions.sendTranscriptionCRM(
        conversation._id,
        selectedLocation._id
      )
      if (result.success) {
        result.data.lastMessage._conversation_id = conversation._id
        addToList && addToList(result.data.lastMessage, true)
        refreshConversation && refreshConversation(conversation, false)
        notifySuccess(getText('NTF_SUCCESS_SEND_TO_CRM'))
      } else {
        notifyError(result.errMsg || getText('ERR_GENERAL'))
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    const loadSelecedLocation = async () => {
      setDropOptions([])

      let result
      if (conversation._location_id) {
        result = await locationActions.getSingle(conversation._location_id)
        if (result.success) {
          setSelectedLocation(result.data)
          setCRMDropDownItems(result.data)
        }
      } else {
        result = await locationActions.getSingle(user._location_id)
        if (result.success) {
          setSelectedLocation(result.data)
          setCRMDropDownItems(result.data)
        }
      }
    }
    loadSelecedLocation()
  }, [conversation._id, conversation._location_id])

  return dropOptions.length ? (
    <div className='crm_items_block'>
      <span className='crm_items_block--title'>{getText('WORD_CRM')}</span>
      {dropOptions.map((item, index) => {
        return (
          <MatButton
            size={'small'}
            typeButton={'white'}
            key={index}
            loading={item.value === 'push_to_crm' ? loading : false}
            buttonText={item.name}
            onClick={() => {
              if (item.value === 'push_to_crm') {
                sendCRM()
              } else if (item.value === 'open_in_crm') {
                if (isMobileApp) {
                  myWebViewPost(getCRMItem(selectedLocation))
                } else {
                  window.open(getCRMItem(selectedLocation), '_blank')
                }
              }
            }}
          />
        )
      })}
    </div>
  ) : null
}

export default MessageCenterHeaderCRMs
